.register{
    margin:20px auto;
    width: 750px;
    min-height: 600px;
    
}

.input-100{
    width:100%;
}
.input-101{
    width:290px;
}

.icon-large{
    width:80px;
}
.register-label{
    color:#0caf4b;
}
.required:after { content:" *";color:red; }

.register-body-tran {
    overflow: hidden;
    margin: 0 auto;
    background: url("/assets/layout/images/register/bk_egister_u2.jpg")  no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.register-body-tran .register-panel {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 0 ;
    border-radius: 0;
    padding: 50px;
    bottom: 0;
    text-align: center;
    margin-bottom: 200px;
}
@media (max-width: 450px) {
    .register-body-tran .register-panel {
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin-bottom: 0 ;
        border-radius: 0;
        padding: 10px;
        bottom: 0;
        text-align: center;
        margin-bottom: 200px;
    }
    }

.register-body-tran .register-panel .register-panel-content {
    text-align: left;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    top: 50px;
   /* margin-left: -400px;*/
}

.register-panel-content-header-icon{
    text-align: right;
}

.register-panel-content-header{
    text-align: right;
}
.register-panel-content-sub-header{
    text-align: right;
    color: #0caf4b;
    margin-top:-10px;
}

/*
.custom .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.custom .p-scrollpanel-bar {
    background-color: #0caf4b;
    opacity: 1;
    transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
    background-color: #0caf4b;
}
*/