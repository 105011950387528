
    .dashboard .overview-box.overview-box-5 {
        background-color: #538EC8;
        border: solid 1px rgba(67, 69, 96, 0.1);
        color: #e9f4ff;
    }

    .dashboard .product-statistics table td.col-overview.col-withdraw div {
        background-color: #ffffff;
        color: #538EC8;
    }