body {
    font-family: "Kanit";
    font-size: 14px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: 10px;
}

.h1, h1 {
    font-size: 2em;
}

.h2, h2 {
    font-size: 1.5em;
}

.h3, h3 {
    font-size: 1.17em;
}

.landing-body .landing-wrapper #pricing > div .ui-g-12 .price-type {
    border: 1px solid #dddddd;
    height: 100%;
    box-sizing: border-box;
    border-radius: 3px;
}

.price-header {
    padding: 35px 10px !important;
    text-align: right;
}

.price-header h3, .price-header .price-for {
    color: #fff !important;
    text-align: right;
    margin-bottom: 5px;
    margin-top: 0px;
}

.price-content .list-group-item {
    color: #6ba6d9;
    padding: 10px 10px 10px 10px;
}

.price-content .list-group-item.button-section {
    padding: 10px 20px 10px 20px;
}

.price-content .list-group-item .fa {
    margin-left: 10px;
}

.price-content .list-group-item span {
    margin-left: 12px;
    color: #000;
}

.price-content .list-group-item.no-border-bottom {
    border-bottom-width: 0px;
}

.price-content .button-section span {
    margin: 0;
}

.price-content .list-group-item span.p-button-text {
    color: #fff;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1px;
}

/* BUTTON */
.landing-body .landing-wrapper #pricing > div .ui-g-12 .price-type .price-footer {
    padding: 10px 60px 30px 60px;
}

body .ui-button.ui-button-text-only .ui-button-text {
    padding: 6px 12px;
}

body .p-button {
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 14px;
    padding: 0.429em 0.857em;
    padding-top: 10px;
    margin-bottom: 10px;
    /*width:100%;*/
}

body .p-button.info-btn {
    background-color: #5bc0de;
    border-color: #46b8da;
    color: #fff;
}

body .p-button.btn-block {
    width: 100%;
}


    .dashboard .overview-box.overview-box-5 {
        background-color: #538EC8;
        border: solid 1px rgba(67, 69, 96, 0.1);
        color: #e9f4ff;
    }

    .dashboard .product-statistics table td.col-overview.col-withdraw div {
        background-color: #ffffff;
        color: #538EC8;
    }
.image-cropper {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.img-cover {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.docs h1 {
    margin-top: 28px;
    margin-bottom: 14px;
}

.docs pre {
    font-family: monospace;
    background-color: #dee4e9;
    color: #292B2C;
    padding: 1em;
    font-size: 14px;
    border-radius: 3px;
    overflow: auto;
}

.docs p {
    line-height: 1.5;
}

.inline-code {
    background-color: #0C2238;
    color: #dddddd;
    font-style: normal;
    font-size: 13px;
    padding: 0 .5em;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.icon-grid div.p-col-12 {
    color: #757575;
    text-align: center;
    padding: 16px;
    font-size: 12px;
}

.icon-grid i {
    display: block;
    margin: 0 auto;
    font-size: 24px;
}

pre {
    font-family: monospace;
    background-color: #0C2238;
    color: #dddddd;
    padding: 1em;
    font-size: 14px;
    border-radius: 3px;
    overflow: auto;
}

.shadow-box {
    background-color: #607D8B;
    width: 100px;
    height: 100px;
}
.register-body-gen {
    overflow: hidden;
    margin: 0 auto;
  
    background: url("/assets/layout/images/register/bk_egister_u1.jpg")  no-repeat;
  
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.register-label{
    color:#0caf4b;
}
.register-body-gen .register-panel {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 0 ;
    border-radius: 0;
    padding: 50px;
    bottom: 0;
    text-align: center;
    margin-bottom: 200px;
}

@media (max-width: 450px) {
.register-body-gen .register-panel {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 0 ;
    border-radius: 0;
    padding: 10px;
    bottom: 0;
    text-align: center;
    margin-bottom: 200px;
}
}

.register-body-gen .register-panel .register-panel-content {
    text-align: left;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    top: 50px;
   /* margin-left: -400px;*/
}

.register-panel-content-header-icon{
    text-align: right;
}

.register-panel-content-header{
    text-align: right;
}
.register-panel-content-sub-header{
    text-align: right;
    color: #0caf4b;
    margin-top:-10px;
}

/*
.custom .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.custom .p-scrollpanel-bar {
    background-color: #0caf4b;
    opacity: 1;
    transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
    background-color: #0caf4b;
}
*/
.register{
    margin:20px auto;
    width: 750px;
    min-height: 600px;
    
}

.input-100{
    width:100%;
}
.input-101{
    width:290px;
}

.icon-large{
    width:80px;
}
.register-label{
    color:#0caf4b;
}
.required:after { content:" *";color:red; }

.register-body-tran {
    overflow: hidden;
    margin: 0 auto;
    background: url("/assets/layout/images/register/bk_egister_u2.jpg")  no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.register-body-tran .register-panel {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 0 ;
    border-radius: 0;
    padding: 50px;
    bottom: 0;
    text-align: center;
    margin-bottom: 200px;
}
@media (max-width: 450px) {
    .register-body-tran .register-panel {
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin-bottom: 0 ;
        border-radius: 0;
        padding: 10px;
        bottom: 0;
        text-align: center;
        margin-bottom: 200px;
    }
    }

.register-body-tran .register-panel .register-panel-content {
    text-align: left;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    top: 50px;
   /* margin-left: -400px;*/
}

.register-panel-content-header-icon{
    text-align: right;
}

.register-panel-content-header{
    text-align: right;
}
.register-panel-content-sub-header{
    text-align: right;
    color: #0caf4b;
    margin-top:-10px;
}

/*
.custom .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.custom .p-scrollpanel-bar {
    background-color: #0caf4b;
    opacity: 1;
    transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
    background-color: #0caf4b;
}
*/
.register-body {
    overflow: hidden;
    margin: 0 auto;
    
    background: url("/assets/layout/images/register/bk_egister_u3.jpg")  no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.register-label{
    color:#0caf4b;
}
.register-body .register-panel {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 0 ;
    border-radius: 0;
    padding: 50px;
    bottom: 0;
    text-align: center;
    margin-bottom: 200px;
}

@media (max-width: 450px) {
    .register-body .register-panel {
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin-bottom: 0 ;
        border-radius: 0;
        padding: 10px;
        bottom: 0;
        text-align: center;
        margin-bottom: 200px;
    }
    }


.register-body .register-panel .register-panel-content {
    text-align: left;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    top: 50px;
   /* margin-left: -400px;*/
}

.register-panel-content-header-icon{
    text-align: right;
}

.register-panel-content-header{
    text-align: right;
}
.register-panel-content-sub-header{
    text-align: right;
    color: #0caf4b;
    margin-top:-10px;
}

/*
.custom .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.custom .p-scrollpanel-bar {
    background-color: #0caf4b;
    opacity: 1;
    transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
    background-color: #0caf4b;
}
*/

.abc {
    
}
