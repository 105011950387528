body {
    font-family: "Kanit";
    font-size: 14px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: 10px;
}

.h1, h1 {
    font-size: 2em;
}

.h2, h2 {
    font-size: 1.5em;
}

.h3, h3 {
    font-size: 1.17em;
}

.landing-body .landing-wrapper #pricing > div .ui-g-12 .price-type {
    border: 1px solid #dddddd;
    height: 100%;
    box-sizing: border-box;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.price-header {
    padding: 35px 10px !important;
    text-align: right;
}

.price-header h3, .price-header .price-for {
    color: #fff !important;
    text-align: right;
    margin-bottom: 5px;
    margin-top: 0px;
}

.price-content .list-group-item {
    color: #6ba6d9;
    padding: 10px 10px 10px 10px;
}

.price-content .list-group-item.button-section {
    padding: 10px 20px 10px 20px;
}

.price-content .list-group-item .fa {
    margin-left: 10px;
}

.price-content .list-group-item span {
    margin-left: 12px;
    color: #000;
}

.price-content .list-group-item.no-border-bottom {
    border-bottom-width: 0px;
}

.price-content .button-section span {
    margin: 0;
}

.price-content .list-group-item span.p-button-text {
    color: #fff;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1px;
}

/* BUTTON */
.landing-body .landing-wrapper #pricing > div .ui-g-12 .price-type .price-footer {
    padding: 10px 60px 30px 60px;
}

body .ui-button.ui-button-text-only .ui-button-text {
    padding: 6px 12px;
}

body .p-button {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 14px;
    padding: 0.429em 0.857em;
    padding-top: 10px;
    margin-bottom: 10px;
    /*width:100%;*/
}

body .p-button.info-btn {
    background-color: #5bc0de;
    border-color: #46b8da;
    color: #fff;
}

body .p-button.btn-block {
    width: 100%;
}
